.file-directory {
  min-width: 280px;
  overflow: auto;
}

.file-nav {
  display: flex;
  flex-flow: column;

  &.file-nav--flow-row {
    flex-flow: row;
  }

  @include responsive(SM) {
    flex-flow: row;
    align-items: center;
    justify-content: space-between;
  }

  .ant-breadcrumb {
    display: flex;
    flex-wrap: wrap;

    & > span {
      display: inline-flex;

      .ant-breadcrumb-link {
        max-width: 180px;

        @include text-ellipsis;
      }
    }
  }
}

.file-nav-hd {
  display: flex;
  flex-flow: row;
  align-items: center;
  justify-content: space-between;

  .ant-breadcrumb {
    display: flex;
    flex-wrap: wrap;

    & > span {
      display: inline-flex;

      .ant-breadcrumb-link {
        max-width: 180px;

        @include text-ellipsis;
      }
    }
  }
}

.file-actions {
  display: flex;
  align-items: center;

  .ant-radio-group {
    display: flex;
  }

  @media only screen and (max-width: $SM) {
    margin-top: 10px;

    @include auto-fill-grid-box(10px, 10px, 47.5%);

    .ant-divider {
      display: none;
    }
  }
}

.file-actions-hd {
  display: flex;
  align-items: center;

  .ant-radio-group {
    display: flex;
  }

  margin-top: 10px;

  @include auto-fill-grid-box(10px, 10px, 48%);

  .ant-divider {
    display: none;
  }
}

.file-list {
  padding: 20px;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
}

.sub-title {
  height: 60px;
  color: #9ba0b1;
  font-size: 12px;
  line-height: 18px;
  margin-top: 4px;
}

.list-item {
  padding: 16px;
  border: solid 1px #efefef;
  border-radius: 4px;
}
.list-item .anticon {
  padding: 4px 0;
}

.item-name {
  padding-top: 10px;
  cursor: pointer;
  overflow-wrap: break-word;
}
.item-name:hover {
  text-decoration: underline;
  color: #0061d4;
}

.ant-modal-body .ant-alert {
  margin-bottom: 16px;
}

.ant-modal-footer button + span {
  margin-left: 8px;
}

.ant-tree-title {
  padding-left: 4px;
  white-space: nowrap;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}
.ant-tree li span.ant-tree-switcher {
  width: 12px;
}
.sider-details-empty {
  width: 250px;
  margin: auto;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sider-details {
  width: 320px;
  margin-left: 10px;
  // text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // justify-content: center;
}
.sider-details .ant-avatar {
  background-color: #fff;
  color: #0061d4;
  margin-bottom: 5px;
}

.icon-actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.icon-name {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.icon-name .anticon {
  margin-right: 10px;
}
.icon-name:hover .name {
  text-decoration: underline;
  color: #0061d4;
}

.ant-modal-body .ant-page-header {
  padding: 0 0 20px;
}
.ant-modal-body .ant-page-header-heading-title {
  font-size: 18px;
}
.ant-modal-body .file-list {
  padding: 20px 0 0;
}
.ant-modal-body .file-nav {
  margin: 0;
}
.ant-modal-body .ant-layout-sider {
  display: none;
}

.ant-table-selection-column,
.ant-table-selection-col {
  display: none;
}

.table-file-list .ant-table-selection-column,
.table-file-list .ant-table-selection-col {
  display: table-cell;
}

.ant-table-tbody > tr.ant-table-row-selected td,
.list-item.selected-item {
  background: #f3f3f3;
}

.upload-progress-noti {
  padding: 12px;
}
.upload-progress-noti .ant-notification-notice-message {
  margin-bottom: 0;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
}
.upload-progress-noti .ant-notification-notice-close {
  top: 12px;
  right: 12px;
}
.clickable-text {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.clickable-text:hover {
  text-decoration: underline;
  color: #0061d4;
}
