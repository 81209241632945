.circle-picker {
  @media screen and (min-width: 415px) {
    width: 380px !important;
  }

  @media screen and (max-width: 414px) {
    width: 270px !important;
  }
}
.bigger {
  @media only screen and (min-width: 321px) {
    font-weight: bold;
    font-size: 16px;
  }
  @media only screen and (max-width: 320px) {
    font-weight: bold;
    font-size: 14px;
  }
}


.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.item-name {
  padding-top: 10px;
  cursor: pointer;
  overflow-wrap: break-word;
}

.item-name:hover {
  text-decoration: underline;
  color: #0061D4;
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-date {
  @media only screen and (max-height: 812px) {
    height: 73px;
    margin: 0 2px;
    padding: 2px 0;
  }
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-content {
  @media only screen and (max-height: 812px) {
    height: 50px;
  }
}

.ant-fullcalendar-fullscreen .ant-fullcalendar-value {
  @media only screen and (max-height: 812px) {
    text-align: center;
  }
}

.nav {
  float: right;
  margin-bottom: 20px;
}

.nav-item {
  margin-right: 4px;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}
