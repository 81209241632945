$XS: 375px;
$SM: 576px;
$MD: 768px;
$LG: 992px;
$XL: 1200px;
$XXL: 1600px;

@mixin responsive($breakpoint) {
  @if $breakpoint == XS {
    @media only screen and (min-width: $XS) {
      @content;
    }
  } @else if $breakpoint == SM {
    @media only screen and (min-width: $SM) {
      @content;
    }
  } @else if $breakpoint == MD {
    @media only screen and (min-width: $MD) {
      @content;
    }
  } @else if $breakpoint == LG {
    @media only screen and (min-width: $LG) {
      @content;
    }
  } @else if $breakpoint == XL {
    @media only screen and (min-width: $XL) {
      @content;
    }
  } @else if $breakpoint == XXL {
    @media only screen and (min-width: $XXL) {
      @content;
    }
  }
}

@mixin line-clamp($numb-of-line) {
  display: -webkit-box;
  -webkit-line-clamp: $numb-of-line;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

@mixin auto-fill-grid-box($gap, $min, $max) {
  display: grid;
  width: 100%;
  grid-gap: $gap;
  grid-template-columns: repeat(auto-fill, minmax($min, $max));
}

@mixin text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
