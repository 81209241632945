@import '../../scss/mixins';

/* Wrappers */
.page-wrapper {
  /* height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center; */
  padding: 20px 74px 20px 0;
  background-color: #0061d4;
}

.page-wrapper.small-screen {
  padding: 20px;

  .main-content {
    padding-bottom: 56px; // padding space so the SpeedDials won't overlap with content
  }
}

.ant-layout.ant-layout-has-sider > .ant-layout {
  border-radius: 5px;
}

/* Overriding style inside the main sider */

.main-sider.ant-layout-sider,
.main-sider.ant-layout-sider-trigger,
.main-sider.ant-layout-sider .ant-menu {
  background-color: #0061d4;
  color: #fff;
}
.main-sider.ant-layout-sider .ant-menu {
  border-right-color: #0061d4;
}
.main-sider.ant-layout-sider .ant-menu a,
.main-sider.ant-layout-sider .ant-tabs,
.main-sider.ant-layout-sider .ant-menu-submenu {
  color: #fff;
}
.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected,
.main-sider.ant-layout-sider .ant-menu-item:active,
.main-sider.ant-layout-sider .ant-menu-submenu-title:active {
  background-color: #1d2037;
}
.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-horizontal)
  .ant-menu-item-selected
  a,
.main-sider.ant-layout-sider .ant-menu-item:active a,
.main-sider.ant-layout-sider .ant-menu-submenu-title:active a,
.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab-active,
.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab:hover,
.main-sider.ant-layout-sider .ant-menu-submenu-selected,
.main-sider.ant-layout-sider .ant-menu-item:hover,
.main-sider.ant-layout-sider .ant-menu-item-active,
.main-sider.ant-layout-sider
  .ant-menu:not(.ant-menu-inline)
  .ant-menu-submenu-open,
.main-sider.ant-layout-sider .ant-menu-submenu-active,
.main-sider.ant-layout-sider .ant-menu-submenu-title:hover,
.main-sider.ant-layout-sider h4 {
  color: #fff;
}
.main-sider.ant-layout-sider .ant-menu-inline .ant-menu-item::after {
  border-right-width: 5px;
}
.main-sider.ant-layout-sider .ant-tabs-ink-bar {
  height: 3px;
}
.main-sider.ant-layout-sider .ant-tabs-bar {
  border-bottom-color: transparent;
}
.main-sider.ant-layout-sider .ant-tabs-nav-scroll {
  text-align: center;
}
.main-sider.ant-layout-sider .ant-tabs-nav .ant-tabs-tab {
  margin-right: 20px;
}
.main-sider.ant-layout-sider .ant-layout-sider-trigger {
  background-color: #0061d4;
}

.main-sider.ant-layout-sider .ant-layout-sider-zero-width-trigger {
  background: #0061d4;
  height: 40px;
  top: 16px;
  right: -16px;
}

/* Logo, profile and footer links */
.logo {
  padding: 20px;
  //text-align: center;
}
.logo-small {
  padding: 10px;
  //text-align: center;
}
.profile {
  padding: 20px;
  display: flex;
  align-items: center;
}
.profile .ant-avatar {
  margin-right: 20px;
}
.profile-links {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 40px;
}
.profile-links a {
  color: #fff;
}
.sider-footer {
  padding: 0 20px;
  position: absolute;
  bottom: 40px;
  width: 100%;
  color: #fff;
  font-size: 12px;
}
.sider-footer a {
  color: #fff;
}
.sider-footer-links span + span {
  margin-left: 8px;
}
.folder-icon {
  width: 125px;
  padding: 25px;
  border: 1px solid #9ba0b1;
  border-radius: 5px;
  margin-top: 10px;
}
.main-wrapper {
  /* height: calc(100% - 70px);
  margin: 20px; */
  padding: 0 20px 80px;
  overflow: auto;
}

.react-grid-Container {
  max-width: 100%;
}

/* Header */
#header {
  position: relative;
  z-index: 10;
  max-width: 100%;
  background: #fff;
  -webkit-box-shadow: 0 2px 8px #f0f1f2;
  box-shadow: 0 2px 8px #f0f1f2;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
#header h2 {
  margin-bottom: 0;
}
.user-popover p:last-child {
  margin-bottom: 0;
}

/* Login form */
.login-form {
  max-width: 320px;
  margin: auto;
}
.login-form-forgot {
  float: right;
}

/* Signup form */
.signup-form {
  height: 100%;
  margin: 20px auto;
  flex-grow: 1;

  @include responsive(MD) {
    margin: 50px auto;
    min-width: 560px;
  }
  @include responsive(LG) {
    margin: 80px auto;
  }
}
.submitted-result {
  @include responsive(SM) {
    width: 480px;
  }
  margin: auto;
}
.steps-actions {
  margin-top: 16px;
}
.steps-actions::after {
  content: '';
  clear: both;
  display: table;
}
.steps-actions .ant-btn-primary {
  float: right;
}
/* Reset password form */
.password-form {
  width: 300px;
  margin: auto;
}
/* Settings */
.list-actions {
  text-align: right;
}
.list-actions .ant-btn + .ant-btn {
  margin-left: 1em;
}

/* AddMultipleField */
.dynamic-field-row {
  margin-bottom: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @include responsive(MD) {
    flex-wrap: nowrap;
  }
}
.dynamic-field-row .dynamic-field {
  flex-grow: 1;
  margin-right: 8px;
}
.dynamic-field-row .anticon-minus-circle-o {
  line-height: 50px;
}

.ant-form-horizontal .ant-input-number {
  width: 100%;
}

.unauth-wrapper {
  background-color: #22253f;
  padding: 20px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  min-height: 100vh;
  flex-flow: column-reverse;

  .logo {
    text-align: center;
    color: #fff;
    // height: 120px;
    z-index: 1;
  }

  .banner {
    text-align: justify;
    margin-top: 20px;
    margin-bottom: 40px;
    line-height: 50px;
    height: auto;
    background: #0061d4;
    color: #e5e7ef;
    border-radius: 30px;
    padding: 0 15px;
    z-index: 1;
    @include responsive(LG) {
      padding: 0 40px;
    }
  }
  // &.unauth-wrapper--row {
  //   @include responsive(LG) {
  //     flex-flow: unset;
  //   }

  //   .logo {
  //     @include responsive(LG) {
  //       flex-grow: 1;
  //     }
  //   }
  // }
}
.unauth-content {
  background-color: #fff;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px;
  min-height: 80vh;
  min-width: 90vw;
  max-width: 100%;
  height: auto;
  box-shadow: 5px 5px 15px 15px rgba(0, 0, 0, 0.1);

  @include responsive(LG) {
    min-width: 700px;
    max-width: 1080px;
    min-height: 650px;
  }
}
.unauth-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 0;
  text-align: right;
  // background-color: #0061d4;
}
.unauth-bg .unauth-bg-img {
  width: 100%;
  height: 100%;
  // opacity: 0.7;
  background-image: url(../../assets/efiling_background.jpg);
  background-size: cover;
}
.unauth-content-footer {
  display: flex;
  justify-content: space-between;
  color: #9ba0b1;
  font-size: 11px;
  flex-flow: column-reverse;
  align-items: center;

  @include responsive(XS) {
    font-size: 12px;
  }
  @include responsive(SM) {
    flex-flow: unset;
  }
}
.unauth-content-footer-links span + span {
  margin-left: 6px;
}
.unauth-content-header {
  text-align: right;
}
.unauth-content-header span + button {
  margin-left: 20px;
}

.form-header {
  margin-bottom: 24px;
}

.mentions {
  margin-bottom: 4px;
}
.mentions--multiLine .mentions__highlighter {
  border: 1px solid transparent;
  border-radius: 4px;
  padding: 4px 11px;
  min-height: 92px;
  line-height: 1.5;
}
.mentions--multiLine .mentions__input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
  outline: 0;
  line-height: 1.5;
}
.mentions__input::placeholder {
  color: #bfbfbf;
}
.mentions__suggestions {
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.65);
  line-height: 1.5;
  list-style: none;
  border-radius: 4px;
  outline: none;
  -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.mentions__suggestions__list {
  max-height: 250px;
  overflow: auto;
  outline: none;
}
.mentions__suggestions__item {
  position: relative;
  display: block;
  min-width: 100px;
  padding: 5px 12px;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.65);
  font-weight: normal;
  line-height: 22px;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-transition: background-color 0.3s ease;
  transition: background-color 0.3s ease;
}
.mentions__suggestions__item:first-child {
  border-radius: 4px 4px 0 0;
}
.mentions__suggestions__item:last-child {
  border-radius: 0 0 4px 4px;
}
.mentions__suggestions__item--focused {
  background-color: #b3c7bb;
}
.mentions__mention {
  color: #0061d4;
  pointer-events: none;
}

.speed-dial .MuiFab-primary,
.speed-dial .MuiFab-primary:hover {
  background-color: #afcff4;
}

/* Editable row */
.editable-row .ant-form-explain {
  position: absolute;
  font-size: 12px;
  margin-top: -4px;
}
.editable-row .row-edit-actions .ant-btn-link {
  padding: 0;
}

.references-tooltip {
  max-width: 420px;
}

.ant-steps-vertical canvas {
  max-width: 72px;
  max-height: 72px;
}
.ant-steps-vertical .ant-steps-item {
  padding-bottom: 40px;
}
.ant-steps-vertical .ant-steps-item:last-child {
  padding-bottom: 0;
}
.ant-steps-vertical
  > .ant-steps-item
  > .ant-steps-item-container
  > .ant-steps-item-tail {
  left: 60px;
  padding-top: 125px;
}
.ant-steps-vertical .ant-steps-item-icon {
  width: 120px;
  margin-right: 46px;
}
.ant-steps-item-custom .ant-steps-item-icon > .ant-steps-icon {
  width: 120px;
  height: 120px;
  line-height: 120px;
  background-color: #f2f3f7;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.ant-popover {
  z-index: 1050;
}

.speed-dial .MuiSpeedDialAction-staticTooltipLabel {
  font-family: 'Overpass', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
}

.storage-progress .ant-progress-outer {
  width: 200px;
}
.storage-progress .ant-progress-text {
  width: auto;
}
.storage-progress.ant-progress-status-normal .ant-progress-bg {
  background-color: #fbbc04;
}
.storage-progress.ant-progress-status-exception .ant-progress-bg {
  background-color: #c80000;
}
.storage-progress.ant-progress-status-exception .ant-progress-text {
  color: #c80000;
}
.storage-progress .ant-progress-success-bg {
  background-color: #0061d4;
}

.custom-tooltip {
  border: solid 1px #e5e7ef;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.05);
  background-color: #fff;
  z-index: 99;
  padding: 1em 1em 0;
}

.setup-mfa-steps,
.reset-password-steps {
  margin: 30px 0 20px;
}
.setup-mfa-steps .ant-steps-item-icon,
.setup-mfa-steps .ant-steps-item-content {
  max-width: 165px;
}
.setup-mfa-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item,
.reset-password-steps.ant-steps-horizontal:not(.ant-steps-label-vertical)
  .ant-steps-item {
  white-space: normal;
}

.reset-password-steps .ant-steps-item-icon,
.reset-password-steps .ant-steps-item-content {
  max-width: 140px;
}

/* fix minor alignment issue, which doesn't occur on Antd? */
.ant-table-row-expand-icon {
  line-height: 17px;
}

.centered-content-modal .ant-modal-confirm-title,
.centered-content-modal .ant-modal-confirm-content {
  text-align: center;
}

.mfa-form {
  margin: 70px auto auto;
  @include responsive(SM) {
    width: 300px;
    margin: 150px auto auto;
  }

  .mfa-helper {
    display: flex;
    align-items: baseline;
  }
}

.setup-mfa-form {
  margin: auto;

  @include responsive(MD) {
    width: 600px;
  }
}

.fullname-field.custom-width {
  width: 100%;

  @include responsive(SM) {
    width: calc(50% - 10px);
  }
}

.plan {
  display: flex;
  margin: 0 -8px;
  flex-direction: column;

  .plan__cards {
    flex-grow: 1;
    margin: 8px;
    display: flex;
    flex-direction: column;
  }

  @include responsive(LG) {
    flex-direction: row;

    .plan__cards {
      width: 200px;
      margin: 0 8px;
    }
  }

  @include responsive(XL) {
    min-width: 960px;
  }

  @include responsive(XXL) {
    min-width: 980px;
  }
}

// Temp fix for modal content
// TODO: remove this when all the content is optimized on mobile
// .ant-modal {
//   max-width: unset;
// }

.add-button {
  margin-top: 29px;
  margin-left: 8px;

  @include responsive(SM) {
    margin-top: 39px;
  }

  &.no-label {
    margin-top: 0;
  }
}
.ant-table-expanded-row .ant-table-fixed-left table {
  background-color: #fbfbfb;
}

.td-res {
  min-width: 120px;

  @include responsive(SM) {
    width: 200px;
    min-width: 150px;
  }

  @include responsive(LG) {
    min-width: 200px;
  }
}

.custom-addon-after {
  margin-top: 21px;

  @include responsive(SM) {
    margin-top: 0px;
  }
}

.text-ellipsis {
  max-width: 180px;
  @include text-ellipsis();
}

.flex-container-gap {
  display: inline-flex;
  flex-wrap: wrap;
  margin: -12px 0 0 -12px !important;
  width: calc(100% + 12px);

  & > * {
    margin: 12px 0 0 12px;
  }
}

.auto-gap {
  & > * {
    margin: 0 4px 8px;
  }
  margin: 0 -4px;
}

.appoint-deputies-alert {
  display: flex;
  flex-flow: column;

  .appoint-deputies-alert-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;

    button {
      margin: 5px;
    }
  }

  @include responsive(XL) {
    flex-flow: row;

    .appoint-deputies-alert-actions {
      flex-wrap: nowrap;
      justify-content: unset;

      button {
        margin: 0 5px;

        &:first-child {
          margin-left: 30px;
        }
      }
    }
  }
}

.page-description {
  display: flex;
  flex-flow: column;

  .page-description-action {
    margin-top: 10px;
    margin-left: 0;
  }

  @include responsive(SM) {
    align-items: center;
    flex-flow: row;

    .page-description-action {
      margin-top: 0;
      margin-left: 10px;
    }
  }
}

.add-deputy-modal {
  max-width: 1000px;
}

.legacy-info {
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  .legacy-actions {
    display: flex;
    flex-flow: column;
    margin-top: 10px;

    .ant-btn {
      margin-left: 0;
      margin-top: 10px;
    }
  }

  @include responsive(MD) {
    flex-flow: row;

    .legacy-actions {
      height: 50px;
      flex-flow: row;
      align-items: center;
      margin-top: 0;

      .ant-btn {
        margin-left: 10px;
        margin-top: 0;
      }
    }
  }
}

.setting-content {
  display: flex;
  flex-flow: column;

  .setting-menu {
    width: auto;

    .ant-menu-horizontal {
      .anticon {
        margin-right: 0;
      }
      .menu-item-title {
        display: none;
      }
    }
  }

  .setting-outlet {
    flex-grow: 1;
    padding-bottom: 80px;
    max-width: 728px; // fix overflow table with Medium screen

    @include responsive(MD) {
      width: 60vw;
    }

    @include responsive(LG) {
      max-width: 100%;
    }
  }

  @include responsive(MD) {
    flex-flow: row;

    .setting-menu {
      width: 180px;
    }

    .setting-outlet {
      padding-left: 20px;
    }
  }
}

.payment-header {
  flex-wrap: wrap;
  margin-top: 10px;
  height: auto !important;

  @include responsive(MD) {
    margin-top: 0;
  }

  .simple-header-extra {
    display: flex;
    margin-top: 10px;
    flex-wrap: wrap;

    .ant-btn {
      margin-bottom: 10px;
    }

    @include responsive(SM) {
      margin-top: 0;

      .ant-btn {
        margin-bottom: 0;
      }
    }
  }
}

.asset-liability-modal {
  max-width: 1200px;

  form .form-right {
    @include responsive(LG) {
      border-left: 1px solid #e8e8e8;
    }
  }
}

.contacts-header {
  padding: 20px 0;
  margin-bottom: 20px;

  .ant-page-header-heading-extra > * {
    margin: 4px 8px 4px 0;

    &:last-child {
      margin-right: 0;
    }
  }

  .ant-select {
    width: 135px;
  }

  .contacts-search {
    width: 80vw;
    max-width: 240px;

    @include responsive(XS) {
      max-width: 280px;
    }

    @include responsive(SM) {
      max-width: 480px;
    }
  }
}

.contacts-content {
  background-color: #fff;
  padding-bottom: 20px;

  @include responsive(MD) {
    padding: 0 20px 20px;
  }
}

.contacts-result {
  @include responsive(MD) {
    height: 100%;
  }
}

.custom-badge-size .ant-badge-count {
  min-width: 16px;
  height: 16px;
  line-height: 18px;
  border-radius: 8px;
  padding: 0 3px;
  font-size: 10px;
}

.dropdown-button {
  text-align: center;
  display: block;
  padding: 15px 25px;
  color: #0061d4;
}

.ant-calendar {
  @include responsive(MD) {
    width: 450px;
  }
}

.yellow-folder {
  transform: translate(100px 10px);
}

.ant-card-body {
  padding: 15px;
}

.ant-card-head{
  height: 99px;
}
.custom_plan_card .ant-card-head{
  height: 60px;
}